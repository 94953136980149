import React, { Component } from "react";
import "./OtpForm.css"
import "./Modal.css"
import config from "./config.json"
import closeIcon from './icons/back.svg';

class RecurrentPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            code: "",
            error: ""
        };
    }

    hide(e) {
        this.setState({
            isShow: false
        });
    }

    render() {
        const { isShow = false, code } = this.props;
        if (!isShow) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <section className="section">
                        <div className="container content">
                            <div className="columns is-centered">
                                <div className="column is-10">
                                    <h1>Соглашение о присоединении к рекуррентной системе платежей</h1>
                                    <div styles="text-align: justify;">
                                        <div className="block">Настоящее Соглашение (далее – «Соглашение») заключается между Индивидуальным предпринимателем Ковалёвым Максимом Николаевичем, (ИНН 470612825518, ОГРНИП 323470400070004) (далее – «Исполнитель») и любым дееспособным физическим лицом (далее – «Клиент»), заключающим публичную оферту (далее – «Договор») посредством использования сайта Исполнителя <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>, имеющим намерение использовать сервис уплаты очередной части стоимости услуг по Договору банковской картой путем автоматического списания денежных средств с банковской карты Клиента, указанной Клиентом при оплате по Договору.
                                            <p>Оформление Заказа выражает полное и безоговорочное согласие Клиента со всеми условиями настоящего Соглашения. Настоящее Соглашение является договором присоединения по смыслу ст. 428 Гражданского кодекса РФ.</p>
                                            <p>Нажатие кнопки «Оплатить 150 ₽» означает, что Клиент ознакомился с условиями настоящего Соглашения, понял все положения настоящего Соглашения и согласился считать все положения настоящего Соглашения юридически обязывающими.</p>
                                        </div>
                                        <div className="block">
                                            <h3>1. Термины и Определения</h3>
                                            <p>1. В целях настоящего Соглашения нижеприведенные термины используются в следующих значениях:</p>
                                            <p>1.1. Соглашение — настоящий документ, опубликованный на сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>.</p>
                                            <p>1.2. Клиент — полностью дееспособное физическое лицо, заключившее с Исполнителем публичную оферту.</p>
                                            <p>1.3. Сайт — интернет-ресурс, содержащий полную и актуальную информацию об объеме и содержании предоставляемых Исполнителем услуг, размещенный по адресу <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>.</p>
                                            <p>1.4. Договор — публичная оферта, размещенная на Сайте.</p>
                                            <p>1.5. Сервис «Рекуррентный платеж» — сервис, позволяющий Исполнителю получать автоматические переводы денежных средств с Банковских карт Клиента.</p>
                                            <p>1.6. Банк — кредитная организация, осуществляющая выпуск Банковских карт на территории Российской Федерации в соответствии с законодательством Российской Федерации и на основании договоров, заключенных между Банком и Клиентом.</p>
                                            <p>1.7. Банковская карта — расчётная или кредитная карта, эмитентом которой является кредитная организация, являющаяся инструментом безналичных расчётов, предназначенная для совершения Клиентами Банка операций с денежными средствами, находящимися у Банка на банковских счетах Клиентов Банка или с денежными средствами, предоставленными Банком в кредит Клиентам Банка в соответствии с законодательством Российской Федерации, а также договором банковского счёта, или в пределах установленного лимита, в соответствии с условиями кредитного договора между Банком и Клиентом Банка, при условии, что такая расчётная или кредитная карта может быть использована для оплаты Услуг Исполнителя. Оплата Услуг Исполнителя в соответствии с настоящим Соглашением возможна Банковскими картами зарегистрированными в соответствии с действующим законодательством Российской Федерации.</p>
                                            <p>1.8. Клиент Банка — физическое лицо, заключившее с Банком договор банковского счета.</p>
                                            <p>1.9. Процессинговый центр — автоматизированная система обработки транзакций по Банковским картам в сфере электронной коммерции, предоставляющая возможность принятия платежей по Банковским картам. Процессинговый центр координирует расчеты между Банком-эмитентом карты, Банком-эквайером (осуществляющим авторизацию транзакций), Сервисом «Рекуррентный платеж» и Клиентом Банковской карты.</p>
                                            <p>1.10. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (субъекту персональных данных).</p>
                                        </div>
                                        <div className="block">
                                            <h3>2. Предмет Соглашения</h3>
                                            <p>2.1. Сервис «Рекуррентный платеж» позволяет Клиенту производить оплату Услуг Исполнителя по Договору путём автоматического списания денежных средств с Банковской карты Клиента в пользу Исполнителя, в порядке и на условиях, согласованных в Договоре.</p>
                                            <p>2.2. После подключения Сервиса «Рекуррентный платеж», с Банковской карты Клиента будут осуществляться переводы денежных средств в размерах, необходимых для надлежащего исполнения обязанности Клиента по Договору.</p>
                                            <p>2.3. Все расчеты с использованием Банковской карты, предусмотренные настоящим Соглашением, производятся в рублях РФ.</p>
                                            <p>2.4. Дополнительная плата за использование Сервиса «Рекуррентный платеж», в том числе за действия по его активации/отключению, не взимается.</p>
                                            <p>2.5. Активация (подключение) Сервиса «Рекуррентный платеж» предполагает выполнение Клиентом следующих действий:</p>
                                            <p>2.5.1. Клиент оформляет подписку на Услуги Исполнителя на Сайте путем выбора интересующих его Услуг из списка и указания своих Персональных данных.</p>
                                            <p>2.5.2. После нажатия на кнопку «Оплатить 150 ₽», Клиент подтверждает достоверность представленных данных и выражает свое согласие с условиями Договора и настоящим Соглашением.</p>
                                            <p>2.5.3. После совершения действий, указанных в п.2.5.2. настоящего Соглашения Клиент перенаправляется на сервис ООО НКО «ЮМани» (https://yookassa.ru) или ООО «КЛАУДПЭЙМЕНТС» (https://cloudpayments.ru) или АО «Тинькофф Банк» (https://tinkoff.ru) в зависимости от выбраного способа оплаты.</p>
                                            <p>2.5.4. где заполняет запрашиваемые данные Банковской карты для осуществления платежа и совершает оплату.</p>
                                            <p>2.5.5. После успешной оплаты Услуг Банковской картой и согласия с настоящим Соглашением, Сервис «Рекуррентный платеж» считается активированным (подключенным), а Исполнитель получает право на автоматическое списание денежных средств с Банковской карты Клиента в счет исполнения обязательств по Договору.</p>
                                            <p>2.6. Выполнение Клиентом действий, указанных в п.2.5. Cоглашения, означает согласие Клиента на оплату услуг по Договору, а также на передачу Банку идентифицирующих Клиента Персональных данных.</p>
                                        </div>
                                        <div className="block">
                                            <h3>3. Порядок Использования Сервиса</h3>
                                            <p>3.1. После подключения Сервиса «Рекуррентный платеж» в порядке, указанном в п.2.5 Соглашения, не ранее, чем за 3 дня и не позднее, чем за 15 минут до окончания текущего оплаченного периода по Договору, с Банковской карты Клиента будут осуществляться переводы денежных средств в сроки и размерах, необходимых для надлежащего исполнения обязательств Клиента по Договору.
                                                <br></br>
                                                После истечения оплаченного периода по Договору будут осуществляться переводы денежных средств в сроки и размерах, необходимых для соответствующего продления Сервиса Клиенту по Договору.</p>
                                            <p>3.2. Непосредственное списание денежных средств осуществляет Банк-эмитент.</p>
                                            <p>3.3. Исполнитель не хранит и не обрабатывает данные Банковской карты Клиента, обеспечивая только запросы в Процессинговый центр и Банк для повторного проведения операции по Банковской карте.</p>
                                            <p>3.4. Исполнитель ни при каких условиях не гарантирует возможность проведения операций по Банковской карте, оставляя разрешение данных вопросов за Банком-эмитентом.</p>
                                            <p>3.5. Клиент гарантирует, что он является держателем Банковской карты, осознанно, корректно и в полном объеме вводит все требуемые реквизиты Банковской карты при активации (подключении) Сервиса «Рекуррентный платеж».</p>
                                            <p>3.6. Подключение Сервиса «Рекуррентный платеж» в порядке, предусмотренном настоящим Соглашением, осуществляется только при наличии технической возможности Исполнителя, Процессингового центра, Банка, Банка-эмитента. Исполнитель, Процессинговый центр, Банк не несут ответственность за невозможность подключения Сервиса «Рекуррентный платеж».</p>
                                            <p>3.7. Отключение (отказ) Клиента от Сервиса «Рекуррентный платеж» происходит путем выбора соответствующей настройки в разделе «Отмена подписки» на сайте Исполнителя <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> или отправки письменного запроса на адрес электронной почты <a className="OfferLink" href="mailto:support@insideoutvpn.ru">support@insideoutvpn.ru</a> об отмене рекуррентных платежей. Отключение Банковской карты от Сервиса «Рекуррентный платеж» происходит в течение 48 часов с момента получения Исполнителем запроса.</p>
                                            <p>3.8. В случае недостаточного количества денежных средств на счете Банковской карты для осуществления очередной оплаты по Договору, Исполнитель имеет право повторять запрос на списание с Банковской карты денежных средств.</p>
                                        </div>
                                        <div className="block">
                                            <h3>4. Права и Обязанности Сторон</h3>
                                            <p>4.1. Исполнитель вправе отказать Клиенту без объяснения причин в возможности активации (подключения) Сервиса «Рекуррентный платеж» для совершения Клиентом очередных платежей по Договору.</p>
                                            <p>4.2. Исполнитель вправе внести изменения в настоящее Соглашение, в любой момент по своему усмотрению, такие изменения вступают в силу с момента размещения на Сайте изменённого текста Соглашения, если иной срок вступления изменений в силу не определён дополнительно в тексте публикации. В случае несогласия Клиента с изменениями, внесёнными в Соглашение, Клиент вправе расторгнуть Соглашение в порядке, изложенном в настоящем Соглашении. Клиент считается согласившимся с изменениями и дополнениями, внесенными в Соглашение, если в течение 10 (Десяти) дней с момента опубликования Соглашения, он не обратился к Исполнителю с предложением о расторжении Соглашения.</p>
                                            <p>4.3. Исполнитель не несёт ответственности за временную неработоспособность Сервиса «Рекуррентный платеж» по не зависящим от Исполнителя причинам. В этом случае Клиент использует иные, согласованные с Исполнителем, способы внесения денежных средств для оплаты очередного платежа по Договору.</p>
                                            <p>4.4. В случае утраты/замены Клиентом Банковской карты, Клиент обязан в течение 3 дней со дня утраты/замены в письменном виде известить об этом Исполнителя с целью исключения реквизитов утраченной платежной Банковской карты и/или указания реквизитов новой Банковской карты. Полученное Исполнителем заявление Клиента об утрате Банковской карты является основанием для приостановления операций по утраченной Банковской карте.</p>
                                            <p>4.5. Клиент обязуется использовать только Банковскую карту, владельцем которой он является, и в отношении которой между Банком и Клиентом заключен соответствующий договор. В случае, если Клиент намеренно использует Банковскую карту иного лица, то он самостоятельно несет ответственность за ущерб, который был или может быть причинен владельцу указанной карты в результате вышеперечисленных действий Клиента.</p>
                                            <p>4.6. Исполнитель вправе отказать Клиенту в использовании Сервиса «Рекуррентный платеж» для оплаты услуг по Договору в случае получения отчета от системы о невозможности списания денежных средств с Банковской карты Клиента.</p>
                                            <p>4.7. Ни одна из Сторон не будет нести ответственности за полное или частичное неисполнение любой из своих обязанностей по настоящему Договору, если неисполнение будет являться следствием обстоятельств непреодолимой силы. Сторона, для которой создалась невозможность исполнения обстоятельств, обязана немедленно в письменном виде уведомить другую сторону о наступлении, предполагаемом сроке действия и прекращения вышеуказанных обстоятельств. Не уведомление или несвоевременное уведомление лишает Стороны права ссылаться на любое вышеуказанное обстоятельство как на основание, освобождающее от ответственности за неисполнение обязательства.</p>
                                        </div>
                                        <div className="block">
                                            <h3>5. Срок Действия Соглашения. Порядок Урегулирования Споров</h3>
                                            <p>5.1. В случае, если по окончании срока действия Договора, Стороны не заявили намерения его расторгнуть или изменить условия, настоящее Соглашение автоматически продлевается на тех же условиях, на срок действия Договора.</p>
                                            <p>5.2. Настоящее Соглашение может быть расторгнуто по инициативе одной из Сторон по основаниям, предусмотренным действующим законодательством Российской Федерации.</p>
                                            <p>5.3. Исполнитель вправе в одностороннем порядке расторгнуть настоящее Соглашение в случае нарушения Клиентом условий настоящего Соглашения, предварительно уведомив об этом Клиента, в срок не позднее чем за 5 календарных дней до предполагаемой даты расторжения.</p>
                                            <p>5.4. Споры Сторон, возникшие в связи с выполнением условий настоящего Соглашения, разрешаются в ходе взаимных консультаций и переговоров.</p>
                                            <p>5.5. В случае не достижения соглашения в ходе переговоров, указанных в п.5.4 Соглашения, заинтересованная Сторона направляет претензию в письменной форме, подписанную уполномоченным лицом, с приложением документов, обосновывающих предъявленные заинтересованной Стороной требования (в случае их отсутствия у другой Стороны), и документы, подтверждающие полномочия лица, подписавшего претензию.</p>
                                            <p>5.6. Сторона, которой направлена претензия, обязана рассмотреть полученную претензию и о результатах уведомить в письменной форме заинтересованную Сторону в течение 10 (десяти) рабочих дней со дня получения претензии.</p>
                                            <p>5.7. В случае не урегулирования разногласий в претензионном порядке, закрепленном настоящим разделом Соглашения, а также в случае неполучения ответа на претензию в течение срока, указанного в п. 5.6 Соглашения, спор передается для рассмотрения в суд в соответствии с применимым законодательством Российской Федерации.</p>
                                        </div>
                                        <div className="block">
                                            <h3>6. Реквизиты</h3>
                                            <p>6.1. Реквизиты Клиента содержатся в заполняемой им форме Заказа на Сайте.</p>
                                            <p>6.2. В случае изменения реквизитов Клиента он обязуется уведомить Исполнителя о произошедших изменениях в письменном виде по адресу электронной почты <a className="OfferLink" href="mailto:support@insideoutvpn.ru">support@insideoutvpn.ru</a>, в противном случае, обязательства по Соглашению будут считаться исполненными не уведомленной Стороной надлежащим образом.</p>
                                            <p>6.3. Реквизиты Исполнителя:</p>
                                            <p>Индивидуальный Предприниматель Ковалёв Максим Николаевич</p>
                                            <p>ИНН: 470612825518</p>
                                            <p>ОГРНИП: 323470400070004</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="CloseButtonModal" onClick={this.props.hide}>
                    Назад
                </div>
            </div>
        );
    }
}

export default RecurrentPayments;