import React, { Component } from "react";
import "./OtpForm.css"
import "./Modal.css"
import config from "./config.json"
import closeIcon from './icons/back.svg';

class Offer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            code: "",
            error: ""
        };
    }

    hide(e) {
        this.setState({
            isShow: false
        });
    }

    render() {
        const { isShow = false, code } = this.props;
        if (!isShow) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <section className="section">
                        <div className="container content">
                            <div className="columns is-centered">
                                <div className="column is-10">
                                    <h1>Договор оферты</h1>
                                    <div styles="text-align: justify;">
                                        <div className="block">
                                            <p>Данная Оферта от владельца интернет-сайта <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>, в дальнейшем «Продавец», в адрес пользователя услуг интернет-сайта, в дальнейшем «Заказчик», является предложением заключить Договор оказания Услуг и определяет основные условия оказания Услуг через интернет-сайт <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> Покупатель, действуя с целью приобретения Услуг, принимает условия настоящего Договора оказания услуг (далее – Договор) на следующих условиях.</p>
                                        </div>
                                        <div className="block">
                                            <h3>1. Определение Терминов</h3>
                                            <p>1.1. Публичная оферта (далее – Оферта) - публичное предложение Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом Договор оказания услуг дистанционным способом (далее - «Договор») на условиях, содержащихся в настоящей Оферте, включая все Приложения.</p>
                                            <p>1.2. Заказ – решение Заказчика заказать Услугу обработки данных через интернет-сайт <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> посредством выбора одного из тарифов и его оплаты, является полным и безоговорочным акцептом Заказчиком настоящей Оферты.</p>
                                            <p>1.3. Продавец предоставляет Услуги обработки данных, как лично, так и с привлечением третьих лиц, включая услуги частного виртуального доступа, а также иные Услуги относящиеся к частной безопасности в сети Интернет и частному доступу к сети Интернет (далее – Услуги) . Услуги включают, но не ограничиваются ВПН и прочими программным обеспечением, дополнениями, приложениями, информацией и информационными материалами доступными Заказчику от Продавца.</p>
                                            <p>1.4. Тарифы – система цен за Услуги обработки данных, представленная на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>.</p>
                                        </div>
                                        <div className="block">
                                            <h3>2. Общие Положения</h3>
                                            <p>2.1. Покупатель осуществляет Заказ Услуг обработки данных через интернет-сайт <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> посредством выбора одного из представленных на интернет-сайте тарифов. Оплатой Заказа Покупатель подтверждает согласие на заключение настоящего Договора (акцепт оферты).</p>
                                            <p>2.2. Заключая Договор, Покупатель подтверждает следующее:</p>
                                            <p>а) Покупатель целиком и полностью ознакомлен, и согласен с условиями настоящего Договора;</p>
                                            <p>б) Покупатель дает разрешение на сбор, обработку и передачу персональных данных на условиях, определенных в Политике обработки персональных данных, размещенной на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>.</p>
                                        </div>
                                        <div className="block">
                                            <h3>3. Цена Услуг</h3>
                                            <p>3.1. Цена на Услуги обработки данных указана на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> на главное странице .</p>
                                            <p>3.2. Продавец имеет право в одностороннем порядке изменить цену на любую услугу.</p>
                                            <p>3.3. Изменение Продавцом цены на оплаченный Заказ не допускается.</p>
                                            <p>3.4. Обязательства Покупателя по оплате Заказа считаются исполненными с момента поступления Продавцу денежных средств в соответствии с Тарифами в полном размере.</p>
                                            <p>3.5. Расчеты между Продавцом и Покупателем за Товар производятся способами, указанными на сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>.</p>
                                        </div>
                                        <div className="block">
                                            <h3>4. Оформление Заказа</h3>
                                            <p>4.1. Заказ Услуг осуществляется Покупателем через сайт <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> либо путём выставления счета на оплату на e-mail клиента.</p>
                                            <p>4.2. При заказе на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> Покупатель обязуется предоставить адрес электронной почты.</p>
                                            <p>4.3. Если Продавцу необходима дополнительная информация, он вправе запросить ее у Покупателя. В случае не предоставления необходимой информации Покупателем, Продавец не несет ответственности за предоставление качественной услуги Покупателю.</p>
                                            <p>4.4. Покупатель несет ответственность за достоверность предоставленной информации при оформлении и оплате Заказа.</p>
                                            <p>4.5. Договор между Продавцом и Покупателем считается заключенным с момента электронного оформления заказа на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> и его оплаты Покупателем.</p>
                                            <p>4.6. Если Покупатель не воспользовался формой «Отмена подписки» на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>, оформленный Покупателем Заказ продлевается на аналогичный срок по истечению срока действия первоначального Заказа Покупателя в соответствии с Тарифами, указанными на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a>, на дату такого продления (авто-продление оказание Услуг).</p>
                                        </div>
                                        <div className="block">
                                            <h3>5. Порядок Оказания Услуг</h3>
                                            <p>5.1. Оказание Услуг осуществляется в порядке, описанном на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> .</p>
                                        </div>
                                        <div className="block">
                                            <h3>6. Прекращение Оказания Услуг</h3>
                                            <p>6.1. Покупатель вправе отказаться от Услуг в любое время действия Договора. Фактически оказанные Услуги Покупатель оплачивает в полном размере в соответствии с Тарифами до даты отказа Покупателя от Услуг.</p>
                                            <p>6.2. Если Покупатель не обратился к Продавцу в письменном виде по адресам, указанным на интернет-сайте <a className="OfferLink" href="https://insideoutvpn.ru">https://insideoutvpn.ru</a> с предложением о прекращении Договора, Договор признается действующим до его прекращения по соглашению Сторон.</p>
                                            <p>6.3. Продавец вправе ограничить Покупателю предоставление Услуг в случае выявления фактов недобросовестного использования Сервиса, а именно:</p>
                                            <p>а) Использование Сервиса Покупателем в целях нарушения законодательства Российской Федерации или других государств;</p>
                                            <p>б) Передачу индивидуального ключа доступа третьим лицам (за исключением близких родственников Покупателя);</p>
                                            <p>в) Превышение квоты на используемый трафик в размере 500 гигабайтов за 30 дней. При превышении этого порога доступ к Сервису будет приостановлен автоматически. В этом случае Покупатель может связаться с Продавцом, используя любые доступные каналы связи, и запросить увеличение лимита трафика. Продавец обязан в ответ на такой запрос от Покупателя не чаще чем один раз в 30 дней увеличить лимит трафика для Покупателя до 1 терабайта за 30 дней. </p>
                                            <p>г) Продавец оставляет за собой право досрочно завершить пробный период, и перевести Покупателя на полный тариф без дополнительных уведомлений в случае выявления неоднократного использования пробного периода.</p>
                                        </div>
                                        <div className="block">
                                            <h3>7. Ответственность Сторон</h3>
                                            <p>7.1. Покупатель обязуется не использовать систему для рассылки спама, сканирования портов и прокси, осуществления массовой рассылки (даже если письма фактически отправляются через другой сервер), не совершать хакерских атак на другие компьютеры или сети.</p>
                                            <p>7.2. Зона покрытия, скорость интернета и качество связи при оказании Услуг могут варьироваться. Продавец обязуются сделать все возможное для того, чтобы Услуги оказывались в любое время (за исключением перерывов на техобслуживание и ремонт). Услуги могут быть недоступны вследствие возникновения не зависящих от Продавца факторов, включая чрезвычайные ситуации, сетевые проблемы или ограничения, помехи, прерывание сигнала, нарушения в работе других служб.</p>
                                            <p>7.3. Продавец не несет ответственности за потерю данных и сообщений, неправильное отражение страниц вследствие проблем с сетью (например, на линии T-1). Для защиты Покупателей Продавец в одностороннем порядке может устанавливать ограничения на использование определенных данных и блокировать обслуживание клиентов. Скорость сети - это номинальная оценочная характеристика, и она не соответствует реальной скорости отправки и приема данных. Фактическая скорость сети зависит от ее конфигурации, способа сжатия данных, нагрузки на сеть и иных факторов. Продавец не дает гарантий, касающихся своевременности получения данных, и не несет ответственности за возможные задержки или потери.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="CloseButtonModal" onClick={this.props.hide}>
                    Назад
                </div>
            </div>
        );
    }
}

export default Offer;