import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <div className="spinner">
      {/* Можно использовать любой другой подходящий элемент, 
           например, изображение с графикой загрузки */}
      <div className="loading"></div>
    </div>
  );
};

export default LoadingSpinner;
