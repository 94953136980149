import React, { Component } from "react";
import "./OtpForm.css"
import "./Modal.css"
import config from "./config.json"
import closeIcon from './icons/back.svg';

class PersonalData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            code: "",
            error: ""
        };
    }

    hide(e) {
        this.setState({
            isShow: false
        });
    }

    render() {
        const { isShow = false, code } = this.props;
        if (!isShow) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <section className="section">
                        <div className="container content">
                            <div className="columns is-centered">
                                <div className="column is-10">
                                    <h1>Политика обработки персональных данных</h1>
                                    <div styles="text-align: justify;">
                                        <div className="block">
                                            <h3>1. Общие Положения</h3>
                                            <p>Настоящий документ (далее – Политика) определяет цели и общие принципы обработки персональных данных, а также реализуемые меры защиты персональных данных у Оператора. Политика является общедоступным документом Оператора и предусматривает возможность ознакомления с ней любых лиц.</p>
                                            <p>Политика действует бессрочно после утверждения и до ее замены новой версией.</p>
                                            <p>В Политике используются термины и определения в соответствии с их значениями, как они определены в ФЗ-152 "О персональных данных".</p>
                                            <p>Обработка персональных данных Оператора осуществляется с соблюдением принципов и условий, предусмотренных настоящей Политикой и законодательством Российской Федерации в области персональных данных.</p>
                                        </div>
                                        <div className="block">
                                            <h3>2. Правовые Основания Обработки Персональных Данных</h3>
                                            <p>Обработка персональных данных осуществляется Оператором на законной и справедливой основе, на основании следующих документов:</p>
                                            <ul>
                                                <li>Конституция Российской Федерации;</li>
                                                <li>Трудовой кодекс Российской Федерации;</li>
                                                <li>Гражданский кодекс Российской Федерации;</li>
                                                <li>Налоговый кодекс Российской Федерации;</li>
                                                <li>Федеральный закон от 27.07.2006г. № 152-ФЗ «О персональных данных»;</li>
                                                <li>Федеральный закон от 06.04.2011г. № 63-ФЗ «Об электронной подписи»;</li>
                                                <li>Федеральный закон от 04.05.2011г. № 99-ФЗ «О лицензировании отдельных видов деятельности»;</li>
                                                <li>Федеральный закон от 07.07.2003г. № 126-ФЗ «О связи»;</li>
                                                <li>Федеральный закон от 01.04.1996г. № 27-ФЗ «Об индивидуальном (персонифицированном) учете в системе обязательного пенсионного страхования»;</li>
                                                <li>Федеральный закон от 22.10.2004г. № 125-ФЗ «Об архивном деле в РФ»;</li>
                                                <li>Федеральный закон от 29.12.2012 N 273-ФЗ "Об образовании в Российской Федерации";</li>
                                            </ul>
                                        </div>
                                        <div className="block">
                                            <h3>3. Порядок и Условия Обработки Персональных Данных</h3>
                                            <p>Обработка персональных данных Оператором ведется смешанным способом, т.е. происходит как, с использованием средств автоматизации, так и без таковых.</p>
                                            <p>Осуществляются следующие действия с персональными данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                                            <p>При обработке обеспечиваются точность, достаточность и актуальность персональных данных по отношению к целям их обработки. При обнаружении неточных или неполных персональных данных производится их актуализация.</p>
                                            <p>Получение и обработка персональных данных в случаях, предусмотренных ФЗ-152, осуществляется Оператором с письменного согласия субъекта персональных данных. Равнозначным согласию в письменной форме на бумажном носителе, содержащему собственноручную подпись субъекта персональных данных, признается согласие в форме электронного документа, подписанного квалифицированной электронной подписью.</p>
                                            <p>Согласие на обработку персональных данных может быть дано субъектом персональных данных или его представителем в любой позволяющей подтвердить факт его получения форме, если иное не установлено ФЗ-152.</p>
                                            <p>Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, Оператором не осуществляется.</p>
                                            <p>Сведения, которые характеризуют физиологические особенности человека и на основе которых можно установить его личность (биометрические персональные данные), могут обрабатываться только при наличии согласия в письменной форме субъекта персональных данных.</p>
                                            <p>Обработка и хранение персональных данных осуществляются не дольше, чем этого требуют цели обработки персональных данных, если отсутствуют законные основания для дальнейшей обработки.</p>
                                            <p>Обработка персональных данных на основании договоров и иных соглашений Оператора, поручений Оператору и поручений Оператора на обработку персональных данных осуществляется в соответствии с условиями этих договоров, соглашений и поручений. Вышеуказанные документы могут определять, в частности:</p>
                                            <ul>
                                                <li>цели, условия, сроки обработки персональных данных;</li>
                                                <li>обязательства сторон, в том числе меры по обеспечению безопасности персональных данных;</li>
                                                <li>права, обязанности и ответственность сторон, касающиеся обработки персональных данных.</li>
                                            </ul>
                                            <p>В случаях, явно не предусмотренных действующим законодательством или договором, обработка осуществляется после получения согласия субъекта персональных данных. Согласие может быть выражено в форме совершения действий, принятия условий договора-оферты, проставления соответствующих отметок, заполнения полей в формах, бланках, или оформлено в письменной форме в соответствии с законодательством.</p>
                                            <p>Оператор предпринимает необходимые правовые, организационные и технические меры для обеспечения безопасности персональных данных, их защиты от несанкционированного (в том числе, случайного) доступа, уничтожения, изменения, блокирования доступа и других несанкционированных действий. К таким мерам, в частности, относятся:</p>
                                            <ul>
                                                <li>назначение сотрудников, ответственных за организацию обработки и обеспечение безопасности персональных данных;</li>
                                                <li>проверка наличия в договорах и включение при необходимости в договоры пунктов об обеспечении конфиденциальности персональных данных;</li>
                                                <li>издание локальных актов по вопросам обработки персональных данных, ознакомление с ними работников, обучение пользователей;</li>
                                                <li>обеспечение физической безопасности помещений и средств обработки, пропускной режим, охрана, видеонаблюдение;</li>
                                                <li>ограничение и разграничение доступа сотрудников и иных лиц к персональным данным и средствам обработки, мониторинг действий с персональными данными;</li>
                                                <li>определение угроз безопасности персональных данных при их обработке, формирование на их основе моделей угроз;</li>
                                                <li>применение средств обеспечения безопасности (антивирусных средств, межсетевых экранов, средств защиты от несанкционированного доступа, средств криптографической защиты информации), в том числе прошедших процедуру оценки соответствия в установленном порядке;</li>
                                                <li>учёт и хранение носителей информации, исключающее их хищение, подмену, несанкционированное копирование и уничтожение;</li>
                                                <li>резервное копирование информации для возможности восстановления;</li>
                                                <li>осуществление внутреннего контроля за соблюдением установленного порядка, проверка эффективности принятых мер, реагирование на инциденты.</li>
                                            </ul>
                                        </div>
                                        <div className="block">
                                            <h3>4. Права Субъектов Персональных Данных</h3>
                                            <p>Субъект персональных данных имеет право отозвать согласие на обработку персональных данных, направив соответствующий запрос Оператору по почте или обратившись лично.</p>
                                            <p>Субъект персональных данных имеет право на получение информации, касающейся обработки его персональных данных, в том числе содержащей:</p>
                                            <ul>
                                                <li>подтверждение факта обработки персональных данных Оператором;</li>
                                                <li>правовые основания и цели обработки персональных данных;</li>
                                                <li>цели и применяемые Оператором способы обработки персональных данных;</li>
                                                <li>наименование и место нахождения Оператора, сведения о лицах (за исключением сотрудников/работников Оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с Оператором или на основании федерального закона;</li>
                                                <li>обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен федеральным законом;</li>
                                                <li>сроки обработки персональных данных, в том числе сроки их хранения;</li>
                                                <li>порядок осуществления субъектом персональных данных прав, предусмотренных Федеральным законом "О персональных данных";</li>
                                                <li>информацию об осуществленной или о предполагаемой трансграничной передаче данных;</li>
                                                <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению Оператора, если обработка поручена или будет поручена такому лицу;</li>
                                                <li>иные сведения, предусмотренные Федеральным законом "О персональных данных" или другими федеральными законами.</li>
                                            </ul>
                                            <p>Субъект персональных данных вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
                                            <p>Если субъект персональных данных считает, что Оператор осуществляет обработку его персональных данных с нарушением требований Федерального закона "О персональных данных" или иным образом нарушает его права и свободы, субъект персональных данных вправе обжаловать действия или бездействие Оператора в уполномоченный орган по защите прав субъектов персональных данных (Федеральная служба по надзору в сфере связи, информационных технологий и массовых коммуникаций - Роскомнадзор) или в судебном порядке.</p>
                                            <p>Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.</p>
                                        </div>
                                        <div className="block">
                                            <h3>5. Права и Обязанности Оператора</h3>
                                            <p>Права и обязанности Оператора определяются действующим законодательством и соглашениями Оператора.</p>
                                            <p>Контроль исполнения требований настоящей Политики осуществляется ответственным за организацию обработки персональных данных.</p>
                                            <p>Ответственность лиц, участвующих в обработке персональных данных на основании поручений Оператора, за неправомерное использование персональных данных устанавливается в соответствии с условиями заключенного между Оператором и контрагентом гражданско-правового договора или Соглашения о конфиденциальности информации.</p>
                                            <p>Лица, виновные в нарушении норм, регулирующих обработку и защиту персональных данных, несут материальную, дисциплинарную, административную, гражданско-правовую или уголовную ответственность в порядке, установленном федеральными законами, локальными актами, соглашениями Оператора.</p>
                                            <p>Политика обработки персональных данных разрабатывается ответственным за организацию обработки персональных данных и вводится в действие после утверждения руководителем Оператора. Предложения и замечания для внесения изменений в Политику следует направлять по адресу <a className="OfferLink" href="mailto:support@insideoutvpn.ru">support@insideoutvpn.ru</a> Политика пересматривается ежегодно для поддержания в актуальном состоянии и актуализируется по мере необходимости.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="CloseButtonModal" onClick={this.props.hide}>
                    Назад
                </div>
            </div>
        );
    }
}

export default PersonalData;