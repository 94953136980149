import React, { Component } from "react";
import "./OtpForm.css"
import closeIcon from './icons/back.svg';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner.js';
import config from './config.json';
import Instruction from "./Instruction.js";
import Offer from "./Offer.js";
import PersonalData from "./PersonalData.js";
import RecurrentPayments from "./RecurrentPayments.js";

class OtpForm extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            isShow: false,
            hideOtpForm: props.hideOtpForm,
            inputValue: "",
            error: "",
            code: "",
            loading: false,
            isChecked: false,
            isShowOffer: false,
            isShowPersonalData: false,
            isShowRecurrentPayments: false
        };
        document.addEventListener("keydown", this.handleKeyDown);
    }

    handleInputChange(e) {
        this.setState({ inputValue: e.target.value });
        if (e.target.value === "") {
            this.setState({ error: "" });
        }
    }

    onContinueClick(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.state.isChecked) {
            var el = document.getElementsByClassName("OfferText")[0];
            el.style.color = "#ff0050";
            var links = document.getElementsByClassName("Link");
            for (var i = 0; i < links.length; i++) {
                links[i].style.color = "#ff0050";
            }
            setTimeout(() => {
                var el = document.getElementsByClassName("OfferText")[0];
                el.style.color = "#686d71";
                var links = document.getElementsByClassName("Link");
                for (var i = 0; i < links.length; i++) {
                    links[i].style.color = "#686d71";
                }
            }, 3000);
            return;
        }
        this.setState({ error: "", loading: true, code: "" });
        if (!/^[0-9][0-9][0-9][0-9]$/.test(this.state.inputValue)) {
            this.setState({ error: "Неправильный код", loading: false });
            setTimeout(() => {
                this.setState({ error: "" });
            }, 2800);
            return;
        }
        axios.post(config.backUrl + '/api/VerifyOtp', {
            data: { email: this.props.email, code: this.state.inputValue },
        })
            .then(response => {
                var result = response.data;
                if (result && result?.accessUrl) {
                    this.setState({ code: result.accessUrl, loading: false });
                    window.scrollTo({
                        top: 200,
                        behavior: 'smooth'
                    });
                } else if (result && result.message !== "" && result.message == "INVALID_OTP") {
                    this.setState({ error: "Неправильный код", loading: false });
                    setTimeout(() => {
                        this.setState({ error: "" });
                    }, 2800);
                }
                else {
                    this.setState({ error: "Что-то пошло не так", loading: false });
                    setTimeout(() => {
                        this.setState({ error: "" });
                    }, 2800);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ error: "Что-то пошло не так", loading: false });
                setTimeout(() => {
                    this.setState({ error: "" });
                }, 2800);
            });
    }

    hide(e) {
        this.setState({
            error: "",
            inputValue: "",
            code: ""
        });
        this.props.hideOtpForm();
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            var button = document.getElementById('continueButton');
            button.click();
        }
    }

    handleCheckboxChange(e) {
        this.setState({ isChecked: !this.state.isChecked });
    }

    showOffer(e) {
        this.setState({ isShowOffer: !this.state.isShowOffer });
    }

    showPersonalData(e) {
        this.setState({ isShowPersonalData: !this.state.isShowPersonalData });
    }

    showRecurrentPayments(e) {
        this.setState({ isShowRecurrentPayments: !this.state.isShowRecurrentPayments });
    }

    render() {
        const { isShow = false } = this.props;
        if (!isShow) return null;

        return (
            <div className="OtpFormWrap">
                <div className="OtpForm">
                    <div className="CloseButton" onClick={this.hide.bind(this)}>
                        {window.screen.width > 768 && !this.state.code ? (<img
                            className="CloseButtonIcon"
                            src={closeIcon}
                            alt="X"
                        />) : "Назад"}
                    </div>
                    {!this.state.code ? (<div className="InputContainer">
                        <input
                            autoFocus
                            className="Input"
                            type="text"
                            placeholder="Введите код, отправленный на Email"
                            value={this.state.inputValue}
                            onChange={this.handleInputChange}
                        />

                    </div>) : null}
                    {!this.state.code ? (<div id="continueButton" className="BlueButton" onClick={this.onContinueClick.bind(this)}>{this.state.loading ? (
                        <LoadingSpinner />
                    ) : this.props.isBuy ? "Оплатить 150 ₽" : "Попробовать"}</div>) : null}
                    {this.state.error != "" ? <div className="error-popup">{this.state.error}</div> : null}
                    {window.screen.width <= 768 && !this.state.code ? (<div className="Offer">
                        <label>
                            <input type="checkbox" checked={this.state.isChecked} onChange={this.handleCheckboxChange.bind(this)} />
                            <span className="OfferText">Я согласен с <a className="Link" href="#" onClick={this.showPersonalData.bind(this)}>политикой обработки персональных данных</a>/
                                <a href="#" className="Link" onClick={this.showOffer.bind(this)}>договором офертой</a>
                                {this.props.isBuy ? (<a href="#" className="Link" onClick={this.showRecurrentPayments.bind(this)}>/соглашением о присоединении к рекуррентной системе платежей</a>) : null}
                            </span>
                        </label>
                    </div>) : null}
                </div>
                {window.screen.width > 768 && !this.state.code ? (<div className="Offer">
                    <label>
                        <input type="checkbox" checked={this.state.isChecked} onChange={this.handleCheckboxChange.bind(this)} />
                        <span className="OfferText">Я согласен с <a className="Link" href="#" onClick={this.showPersonalData.bind(this)}>политикой обработки персональных данных</a>/
                            <a href="#" className="Link" onClick={this.showOffer.bind(this)}>договором офертой</a>
                            {this.props.isBuy ? (<a href="#" className="Link" onClick={this.showRecurrentPayments.bind(this)}>/соглашением о присоединении к рекуррентной системе платежей</a>) : null}
                        </span>
                    </label>
                </div>) : null}
                <Offer isShow={this.state.isShowOffer} hide={this.showOffer.bind(this)}></Offer>
                <PersonalData isShow={this.state.isShowPersonalData} hide={this.showPersonalData.bind(this)}></PersonalData>
                <RecurrentPayments isShow={this.state.isShowRecurrentPayments} hide={this.showRecurrentPayments.bind(this)}></RecurrentPayments>
                <Instruction code={this.state.code} isShow={this.state.code}></Instruction>
            </div >
        );
    }
}

export default OtpForm;