import React, { Component } from "react";

class StartButtons extends Component {
    render() {
        const { showCommunicationTypeChoice, isShow = true } = this.props;
        if (!isShow) 
            return null;

        return (
            <div className='TwoButtons'>
                <div onClick={showCommunicationTypeChoice} id="TryFreeButton" className='BlackButton'>Попробовать бесплатно</div>
                <div onClick={showCommunicationTypeChoice} id="BuyButton" className='WhiteButton'>Купить за 150 ₽ в месяц</div>
            </div>
        );
    }
}

export default StartButtons;