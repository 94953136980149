import React, { Component } from "react";
import "./OtpForm.css"
import closeIcon from './icons/back.svg';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner.js';
import config from './config.json';
import Instruction from "./Instruction.js";

class EmailForm extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            isShow: false,
            hideEmailForm: props.hideEmailForm,
            inputValue: "",
            error: "",
            code: "",
            loading: false
        };
        document.addEventListener("keydown", this.handleKeyDown);
    }

    handleInputChange(e) {
        this.setState({ inputValue: e.target.value });
        if (e.target.value === "") {
            this.setState({ error: "" });
        }
    }

    onContinueClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ error: "", loading: true, code: "" });
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.inputValue)) {
            this.setState({ error: "Неправильный Email", loading: false });
            setTimeout(() => {
                this.setState({ error: "" });
            }, 2800);
            return;
        }
        // this.setState({ loading: false, code: "" });
        //                 this.props.showOtpForm(this.state.inputValue);
        //                 return;
        axios.post(config.backUrl + '/api/SendOtp', {
            data: { email: this.state.inputValue },
        })
            .then(response => {
                var result = response.data
                if (result && result?.success) {
                    if (result.accessUrl) {
                        window.scrollTo({
                            top: 200,
                            behavior: 'smooth'
                        });
                        this.setState({ loading: false, code: result.accessUrl });
                    }
                    else {
                        this.setState({ loading: false, code: "" });
                        this.props.showOtpForm(this.state.inputValue);
                    }
                } else if (result && result.message !== "SUCCESS" && result.message !== "") {
                    this.setState({ error: result.message, loading: false });
                    setTimeout(() => {
                        this.setState({ error: "" });
                    }, 2800);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ error: "Что-то пошло не так", loading: false });
                setTimeout(() => {
                    this.setState({ error: "" });
                }, 2800);
            });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            var button = document.getElementById('continueButton');
            button.click();
        }
    }

    hide(e) {
        this.setState({
            error: "",
            inputValue: "",
            code: ""
        });
        this.props.hideEmailForm();
    }

    render() {
        const { isShow = false } = this.props;
        if (!isShow) return null;
        
        return (
            <div className="OtpFormWrap" onKeyDown={this.handleKeyDown.bind(this)}>
                <div className="OtpForm">
                    <div className="CloseButton" onClick={this.hide.bind(this)}>
                        { window.screen.width > 768 && !this.state.code ? (<img
                            className="CloseButtonIcon"
                            src={closeIcon}
                            alt="X"
                        />) : "Назад"}
                    </div>
                    {!this.state.code ? (<div className="InputContainer">
                        <input
                            autoFocus
                            className="Input"
                            type="email"
                            placeholder="Введите Email"
                            value={this.state.inputValue}
                            onChange={this.handleInputChange}
                        />
                        
                    </div>) : null}
                    {!this.state.code ? (<button id="continueButton"  className="BlueButton" onClick={this.onContinueClick.bind(this)}>{this.state.loading ? (
                        <LoadingSpinner />
                    ) : "Продолжить"}</button>) : null}
                    {this.state.error != "" ? <div className="error-popup">{this.state.error}</div> : null}
                </div>
                <Instruction code={this.state.code} isShow={this.state.code}></Instruction>
            </div >
        );
    }
}

export default EmailForm;