import React, { Component } from "react";
import closeIcon from './icons/back.svg';

class CommunicationTypeChoice extends Component {

    openTelegram(e) {
        window.open("https://telegram.me/vnature_vpn_bot?buy=buy");
    }

    render() {
        const { hideCommunicationTypeChoice, showEmailForm, isShow = true } = this.props;
        if (!isShow)
            return null;

        return (
            <div className='ThreeButtons'>
                <div className="CloseButton" onClick={hideCommunicationTypeChoice}>
                { window.screen.width > 768 ? (<img
                        className="CloseButtonIcon"
                        src={closeIcon}
                        alt="X"
                    />) : "Назад"}
                </div>
                <div onClick={showEmailForm} id="EmailButton" className='BlackButton'>Продолжить с Email</div>
                <div onClick={this.openTelegram.bind(this)} id="TelegramButton" className='TelegramButton'>Продолжить в Telegram</div>
            </div>
        );
    }
}

export default CommunicationTypeChoice;